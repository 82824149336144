import './App.css';
import './auth.js'
import AWS from 'aws-sdk/dist/aws-sdk-react-native'
import { CheckPin } from './auth.js';

function App() {
  return (
    <div className="App">
      {/* <div id='auth'>
        <input placeholder='-' className='authInput'></input>
        <input placeholder='-' className='authInput'></input>
        <input placeholder='-' className='authInput'></input>
        <input placeholder='-' className='authInput'></input>
      </div> */}
    </div>
  );
}

// fetch('https://api.telegram.org/bot5942548605:AAHRwYt9VhqFdoaqzEGOucuBvC5Hxqs4OO0/getUpdates')
// .then((res)=> res.json())
// .then((json)=>{
//     console.log(json.result);
// });

//     fetch('https://api.telegram.org/bot5942548605:AAELiKQpuIrJY3Ct-ntf8JVCM3bMj9oHXmg/sendMessage?chat_id=255606698&text=oi')
//     .then((res)=> console.log(res));

const bucketName = "informe-db";
const region = "sa-east-1";
const accessKeyId = "AKIA27CVRXSOQULL6RG4";
const secretAccessKey = "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";

let database = [];
let databaseNames = [];
let databasePositions = [];
let uploadedFile;
const bot_token = "bot1746496008:AAHdPjD9k_FKS-T_wwCtnHpqngoJXlZg3BY";
const bot_username = "InformeCripto_BOT";
// bot5942548605:AAELiKQpuIrJY3Ct-ntf8JVCM3bMj9oHXmg

// var xhttp = new XMLHttpRequest();
// xhttp.onreadystatechange = function() {
//     if (this.readyState == 4 && this.status == 200) {
//        // Typical action to be performed when the document is ready:
//     //    document.getElementById("demo").innerHTML = xhttp.responseText;
//        database = xhttp.responseText.split('\n');
//     }
// };
// xhttp.open("GET", "database/groups.csv", true);
// xhttp.send();

let checks;
let checked = [];
let groupsCount = 0;

window.onload = () => {
  let authOnScreen = false;
  console.log(CheckPin('0215'));

  const s3 = new AWS.S3({
    region,
    accessKeyId,
    secretAccessKey,
  });

  // fetch(`https://api.telegram.org/${bot_token}/getChatAdministrators?chat_id=${chatID}`)
  // .then(res => res.json()
  // .then((json)=>{
  //     console.log(json.result[0].user.username == bot_username);
  // }))

  downloadDoc(s3).then((res) => {
    uploadedFile = res.Body.toString();
    // console.log("upload: " + uploadedFile);
  });

  downloadFile(s3).then((res) => {
    database = ScanDuplicates(res.Body.toString().split("\n"));
    // console.log(database);

    let text = document.createElement("textarea");
    text.id = "text";
    text.cols = "30";
    text.rows = "10";
    text.placeholder = "Digite o texto a ser enviado aos grupos aqui.";
    // text.value = 'Se você, seu grupo ou canal deseja ficar bem informado basta adicionar esse bot (@InformeCripto_BOT) como adm, enviaremos diariamente notícias sobre o mundo Cripto! Quem sabe antes, sai na frente!!!~~~Conheça outros produtos do grupo informe:~~https://t.me/desinforme~~https://www.youtube.com/@desinformecast~~https://www.instagram.com/desinforme.cast/'
    text.value = `Se você, seu grupo ou canal deseja ficar bem informado basta adicionar esse bot (@InformeCripto_BOT) como adm, enviaremos diariamente notícias sobre o mundo Cripto! Quem sabe antes, sai na frente!!!
        
        
Conheça outros produtos do grupo informe:
        
https://t.me/desinforme

https://www.youtube.com/@desinformecast
        
https://www.instagram.com/desinforme.cast/`;
    document.body.appendChild(text);

    let textToggle = document.createElement("button");
    textToggle.id = "text-toggle";
    textToggle.innerText = "Converter Texto";
    textToggle.addEventListener("mousedown", () => {
      if (!text.value.includes("~")) {
        text.value = text.value.replace(/\n/g, "~");
      } else {
        alert("O texto já está formatado para o Telegram.");
      }
    });
    document.body.appendChild(textToggle);

    let groupsDiv = document.createElement("div");
    groupsDiv.setAttribute("id", "groups-container");
    document.body.appendChild(groupsDiv);

    for (let i = 1; i < database.length; i++) {
      if (database[i] !== "") {
        let div = document.createElement("div");
        div.classList.add("check");
        div.innerHTML =
          "<input type='checkbox'>\n<label>" +
          database[i].split(",")[1] +
          "</label>";
        groupsDiv.appendChild(div);
        groupsCount++;
      }
    }

    let textContainer = document.createElement("div");
    textContainer.setAttribute("id", "text-container");
    document.body.appendChild(textContainer);

    let textTitle = document.createElement("p");
    textTitle.setAttribute("id", "text-title");
    textTitle.innerHTML = "Somente texto";
    textContainer.appendChild(textTitle);

    let textButton = document.createElement("button");
    textButton.innerHTML = "Enviar";
    textContainer.appendChild(textButton);

    let imageContainer = document.createElement("div");
    imageContainer.setAttribute("id", "image-container");
    document.body.appendChild(imageContainer);

    let imageTitle = document.createElement("p");
    imageTitle.setAttribute("id", "text-title");
    imageTitle.innerHTML = "Texto com imagem";
    imageContainer.appendChild(imageTitle);

    let imageButton = document.createElement("button");
    imageButton.innerHTML = "Enviar";
    imageContainer.appendChild(imageButton);

    let audioContainer = document.createElement("div");
    audioContainer.setAttribute("id", "audio-container");
    document.body.appendChild(audioContainer);

    let filesDisplay = document.createElement("p");
    filesDisplay.setAttribute("id", "groups-display");
    filesDisplay.innerHTML = "Texto com arquivo";
    audioContainer.appendChild(filesDisplay);

    console.log("Temos " + groupsCount + " grupos recebendo audios.");

    let submit = document.createElement("button");
    submit.innerHTML = "Enviar";
    audioContainer.appendChild(submit);

    submit.addEventListener("click", () => {
      let auth = document.createElement('div');
      auth.id = 'auth';

      for (let i = 1; i < 5; i++) {
        let authInput = document.createElement('input');
        authInput.classList.add('authInput');
        authInput.placeholder = '-';
        authInput.maxLength = 1;
        auth.appendChild(authInput);
      }

      document.body.appendChild(auth);
      document.querySelectorAll('.authInput')[0].focus();
      authOnScreen = true;

      let input1 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[0].value != '') {
          document.querySelectorAll('.authInput')[1].focus();
          clearInterval(input1);
        }
      }, refreshRate);

      let input2 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[1].value != '') {
          document.querySelectorAll('.authInput')[2].focus();
          clearInterval(input2);
        }
      }, refreshRate);

      let input3 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[2].value != '') {
          document.querySelectorAll('.authInput')[3].focus();
          clearInterval(input3);
        }
      }, refreshRate);

      let input4 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[3].value != '') {
          let finalPin =
            `${document.querySelectorAll('.authInput')[0].value}` +
            `${document.querySelectorAll('.authInput')[1].value}` +
            `${document.querySelectorAll('.authInput')[2].value}` +
            `${document.querySelectorAll('.authInput')[3].value}`;
          if (CheckPin(finalPin)) {
            document.querySelector('#auth').remove();
            let t = document.querySelector("#text");

            checked = [];
            let result = GetChecks(checks, checked);

            for (let i = 0; i < database.length; i++) {
              if (database[i].split(",")[1] !== undefined) {
                databaseNames[i] = database[i].split(",")[1].trim();
              }
            }

            databasePositions = [];
            for (let i = 0; i <= databaseNames.length - 1; i++) {
              if (databaseNames.indexOf(result[i]) !== -1) {
                databasePositions[i] = databaseNames.indexOf(result[i]);
              }
            }

            for (let i = 0; i < databasePositions.length; i++) {
              fetch(
                "https://api.telegram.org/" +
                bot_token +
                "/sendDocument?chat_id=" +
                database[databasePositions[i]].split(",")[0] +
                "&document=" +
                uploadedFile
              );
              setTimeout(() => {
                fetch(
                  "https://api.telegram.org/" +
                  bot_token +
                  "/sendMessage?chat_id=" +
                  database[databasePositions[i]].split(",")[0] +
                  "&text=" +
                  t.value.replace(/~/g, "%0A") +
                  "&parse_mode=HTML"
                );
              }, 500);
            }
          }
          clearInterval(input4);
        }
      }, refreshRate);
    });

    imageButton.addEventListener("click", () => {
      let auth = document.createElement('div');
      auth.id = 'auth';

      for (let i = 1; i < 5; i++) {
        let authInput = document.createElement('input');
        authInput.classList.add('authInput');
        authInput.placeholder = '-';
        authInput.maxLength = 1;
        auth.appendChild(authInput);
      }

      document.body.appendChild(auth);
      document.querySelectorAll('.authInput')[0].focus();
      authOnScreen = true;

      let input1 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[0].value != '') {
          document.querySelectorAll('.authInput')[1].focus();
          clearInterval(input1);
        }
      }, refreshRate);

      let input2 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[1].value != '') {
          document.querySelectorAll('.authInput')[2].focus();
          clearInterval(input2);
        }
      }, refreshRate);

      let input3 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[2].value != '') {
          document.querySelectorAll('.authInput')[3].focus();
          clearInterval(input3);
        }
      }, refreshRate);

      let input4 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[3].value != '') {
          let finalPin =
            `${document.querySelectorAll('.authInput')[0].value}` +
            `${document.querySelectorAll('.authInput')[1].value}` +
            `${document.querySelectorAll('.authInput')[2].value}` +
            `${document.querySelectorAll('.authInput')[3].value}`;
          if (CheckPin(finalPin)) {
            document.querySelector('#auth').remove();
            let t = document.querySelector("#text");

            checked = [];
            let result = GetChecks(checks, checked);

            for (let i = 0; i < database.length; i++) {
              if (database[i].split(",")[1] !== undefined) {
                databaseNames[i] = database[i].split(",")[1].trim();
              }
            }

            databasePositions = [];
            for (let i = 0; i <= databaseNames.length - 1; i++) {
              if (databaseNames.indexOf(result[i]) !== -1) {
                databasePositions[i] = databaseNames.indexOf(result[i]);
              }
            }

            for (let i = 0; i < databasePositions.length; i++) {
              fetch(
                "https://api.telegram.org/" +
                bot_token +
                "/sendPhoto?chat_id=" +
                database[databasePositions[i]].split(",")[0] +
                "&photo=" +
                uploadedFile
              );

              setTimeout(() => {
                fetch(
                  "https://api.telegram.org/" +
                  bot_token +
                  "/sendMessage?chat_id=" +
                  database[databasePositions[i]].split(",")[0] +
                  "&text=" +
                  t.value.replace(/~/g, "%0A") +
                  "&parse_mode=HTML"
                );
              }, 500);
            }
          }
          clearInterval(input4);
        }
      }, refreshRate);
    });

    const refreshRate = 100;
    document.addEventListener('keydown', (e) => {
      if (e.key == 'Backspace' && authOnScreen) {
        let inputs = document.querySelectorAll('.authInput');

        if (inputs[0].value == '') {
          // alert('nope');
        } else if (inputs[1].value == '') {
          inputs[0].focus();
          let input1 = setInterval(() => {
            if (document.querySelectorAll('.authInput')[0].value != '') {
              document.querySelectorAll('.authInput')[1].focus();
              clearInterval(input1);
            }
          }, refreshRate);
        } else if (inputs[2].value == '') {
          inputs[1].focus();
          let input2 = setInterval(() => {
            if (document.querySelectorAll('.authInput')[1].value != '') {
              document.querySelectorAll('.authInput')[2].focus();
              clearInterval(input2);
            }
          }, refreshRate);
        } else if (inputs[3].value == '') {
          inputs[2].focus();
          let input3 = setInterval(() => {
            if (document.querySelectorAll('.authInput')[2].value != '') {
              document.querySelectorAll('.authInput')[3].focus();
              clearInterval(input3);
            }
          }, refreshRate);
        }
      }
    })

    textButton.addEventListener("click", () => {
      let auth = document.createElement('div');
      auth.id = 'auth';

      for (let i = 1; i < 5; i++) {
        let authInput = document.createElement('input');
        authInput.classList.add('authInput');
        authInput.placeholder = '-';
        authInput.maxLength = 1;
        auth.appendChild(authInput);
      }

      document.body.appendChild(auth);
      document.querySelectorAll('.authInput')[0].focus();
      authOnScreen = true;

      let input1 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[0].value != '') {
          document.querySelectorAll('.authInput')[1].focus();
          clearInterval(input1);
        }
      }, refreshRate);

      let input2 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[1].value != '') {
          document.querySelectorAll('.authInput')[2].focus();
          clearInterval(input2);
        }
      }, refreshRate);

      let input3 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[2].value != '') {
          document.querySelectorAll('.authInput')[3].focus();
          clearInterval(input3);
        }
      }, refreshRate);

      let input4 = setInterval(() => {
        if (document.querySelectorAll('.authInput')[3].value != '') {
          let finalPin =
            `${document.querySelectorAll('.authInput')[0].value}` +
            `${document.querySelectorAll('.authInput')[1].value}` +
            `${document.querySelectorAll('.authInput')[2].value}` +
            `${document.querySelectorAll('.authInput')[3].value}`;
          if (CheckPin(finalPin)) {
            document.querySelector('#auth').remove();
            let t = document.querySelector("#text");

            checked = [];
            let result = GetChecks(checks, checked);

            for (let i = 0; i < database.length; i++) {
              if (database[i].split(",")[1] !== undefined) {
                databaseNames[i] = database[i].split(",")[1].trim();
              }
            }

            databasePositions = [];
            for (let i = 0; i <= databaseNames.length - 1; i++) {
              if (databaseNames.indexOf(result[i]) !== -1) {
                databasePositions[i] = databaseNames.indexOf(result[i]);
              }
            }

            for (let i = 0; i < databasePositions.length; i++) {
              fetch(
                "https://api.telegram.org/" +
                bot_token +
                "/sendMessage?chat_id=" +
                database[databasePositions[i]].split(",")[0] +
                "&text=" +
                t.value.replace(/~/g, "%0A") +
                "&parse_mode=HTML"
              );
            }
          }
          clearInterval(input4);
        }
      }, refreshRate);
    });

    let groupsDisplay = document.createElement("p");
    groupsDisplay.setAttribute("id", "groups-display");
    groupsDisplay.innerHTML =
      "Temos " + groupsCount + " grupos recebendo audios!";
    document.body.appendChild(groupsDisplay);

    let all = document.createElement("button");
    all.innerHTML = "Selecionar Tudo";
    document.body.appendChild(all);

    all.addEventListener("click", () => {
      switch (all.innerHTML) {
        case "Selecionar Tudo":
          for (let i = 0; i < document.querySelectorAll(".check").length; i++) {
            document.querySelectorAll(".check")[i].childNodes[0].checked = true;
          }
          all.innerHTML = "Desselecionar Tudo";
          break;
        case "Desselecionar Tudo":
          for (let i = 0; i < document.querySelectorAll(".check").length; i++) {
            document.querySelectorAll(".check")[
              i
            ].childNodes[0].checked = false;
          }
          all.innerHTML = "Selecionar Tudo";
          break;
        default:
          console.log('react chatao');
      }
    });
  });
};

// function SayHi() {
//   fetch(
//     "https://api.telegram.org/" +
//       bot_token +
//       "/sendMessage?chat_id=255606698&text=oi"
//   ).then((res) => console.log(res));
// }

function GetChecks(checks, checked) {
  checks = document.querySelectorAll(".check");
  for (let i = 0; i < checks.length; i++) {
    if (checks[i].childNodes[0].checked) {
      checked.push(checks[i].childNodes[2].innerText);
    }
  }

  return checked;
}

function downloadFile(s3) {
  const downloadParams = {
    Key: "groups.csv",
    Bucket: bucketName,
  };

  return s3.getObject(downloadParams).promise();
}

function downloadDoc(s3) {
  const downloadParams = {
    Key: "uploads.txt",
    Bucket: bucketName,
  };

  return s3.getObject(downloadParams).promise();
}

function ScanDuplicates(array) {
  let finalArray = [];
  let tempArray = [];
  let duplicateCount = 0;

  for (let i = 0; i < array.length; i++) {
    if (tempArray.indexOf(array[i].split(",")[0]) === -1) {
      tempArray.push(array[i].split(",")[0]);
      finalArray.push(array[i]);
    } else {
      duplicateCount++;
    }
  }

  console.warn(
    duplicateCount +
    " grupos duplicados foram encontrados e removidos da lista."
  );

  return finalArray;
}

function Authenticate(func) {

}
//255606698


export default App;
